/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
#root {
  background-color: black;
}
.page-wrapper {
  overflow: hidden;
  padding: 0;
  width: 100%;
}
.text-center {
  text-align: center!important;
}
.translucent-wrapper {
  opacity: 0.33;
}
.callout {
  font-size: 22px;
}
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.banner-background {
  position: absolute;
  z-index: 0;
  background-image: url("/city_50.jpg");
  background-size: cover;
  width: 100%;
  height: 100%;
}
.home-page-wrapper {
  width: 100%;
  padding: 0;
  overflow: hidden;
  position: relative;
  color: #e0e0e0;
  font: normal normal normal 14px/1.4em lulo-clean-w01-one-bold, sans-serif;
  -webkit-font-smoothing: antialiased;
}
.home-page-wrapper .page {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  position: relative;
  padding: 0 24px;
}
.home-page-wrapper .page h2 {
  font-size: 38px;
  line-height: 46px;
  color: #e0e0e0;
  text-align: center;
  font-weight: 400;
  margin: 140px auto 100px;
}
.home-page-wrapper .page h3 {
  margin: 70px auto 70px;
}
.home-page-wrapper .page h1,
.home-page-wrapper .page h2,
.home-page-wrapper .page h3,
.home-page-wrapper .page h4,
.home-page-wrapper .page p {
  will-change: transform;
}
.banner-bg {
  width: 120%;
  height: 200px;
  background: #2c2e3d;
  position: absolute;
  bottom: -200px;
  left: 0%;
  transform-origin: 0;
  transform: rotate(-4deg);
}
.banner-bg-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.banner {
  display: flex;
  align-items: center;
  height: 576px;
}
.banner-wrapper {
  overflow: initial;
  position: relative;
  z-index: 1;
}
.banner .text-wrapper {
  width: 54%;
  max-width: 600px;
  min-height: 320px;
  color: #0D1A26;
}
.banner .text-wrapper h1 {
  font-size: 68px;
  font-weight: 600;
  line-height: 76px;
  margin: 8px 0 28px;
  letter-spacing: 0;
  font-family: "Avenir-Heavy", 'SF UI Display', "Helvetica Neue For Number", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.banner .text-wrapper p {
  font-size: 20px;
  line-height: 40px;
  color: #e0e0e0;
}
.banner .img-wrapper {
  width: 46%;
  max-width: 482px;
  position: absolute;
  right: 0;
  bottom: 26px;
}
svg {
  display: block;
}
svg g {
  transform-origin: 50%;
  transform-box: fill-box;
}
/** page1 **/
.page1 {
  min-height: 784px;
  background: #2c2e3d;
  background: linear-gradient(to bottom, #2c2e3d 0%, #444861 100%);
}
.page1 h2,
.page1 h3 {
  color: #fff !important;
}
.page1-block {
  max-width: 160px;
  text-align: center;
  margin: auto;
  display: block;
  position: relative;
  z-index: 1;
}
.page1-block h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
}
.page1-block p {
  color: #fff;
}
.page1-block:hover .page1-image {
  transform: translateY(-4px);
  box-shadow: 0 6px 10px rgba(5, 26, 180, 0.35);
}
.page1-image {
  width: 120px;
  height: 120px;
  background: #fff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 46px auto 32px;
  transition: transform 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.page1-image img {
  width: 150px;
  display: block;
}
.page1-point-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  overflow: inherit !important;
}
/** page2 **/
.page2 {
  background: #fff;
  min-height: 900px;
  overflow: initial;
  padding-top: 1px;
}
.page2 .page {
  position: relative;
  z-index: 1;
}
.page2 .page h2 {
  margin-bottom: 142px;
}
.page2-content {
  box-shadow: 0 12px 20px #D8E0E6;
  background: #2c2e3d;
}
.page2-components,
.page2-product {
  min-height: 554px;
}
.page2-components {
  background: #2c2e3d;
  padding: 56px;
  color: #fff;
  line-height: 32px;
}
.page2-components h3 {
  font-size: 28px;
  margin: 0 auto 32px;
  color: #fff;
}
.page2-components .components-button-wrapper {
  position: absolute;
  bottom: 48px;
  left: 56px;
}
.page2-components .components-button-wrapper a {
  display: block;
  color: #fff;
  line-height: 1.5em;
  margin-top: 16px;
}
.page2-product {
  background: #fff;
  padding: 48px 56px;
}
.page2-product .product-block {
  margin-bottom: 34px;
}
.page2-product .product-block:last-child {
  margin-bottom: 0;
}
.page2-product .product-block:last-child .block-text-wrapper {
  padding-bottom: 0;
  border-bottom: none;
}
.page2-product .product-block .block-text-wrapper {
  padding-bottom: 35px;
  position: relative;
  border-bottom: 1px solid #EBEDF0;
}
.page2-product .product-block .block-text-wrapper h4 {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 8px;
}
.page2-product .product-block .block-text-wrapper p {
  line-height: 24px;
  margin-bottom: 24px;
}
.page2-product .product-block .block-text-wrapper a {
  display: inline-block;
  line-height: 22px;
  color: #2c2e3d;
  vertical-align: top;
}
.page2-product .product-block .block-text-wrapper a i {
  font-size: 12px;
  vertical-align: middle;
}
.page2-product .product-block .block-image-wrapper {
  height: 104px;
  display: flex;
  align-items: center;
}
.page2-product .product-block .block-image-wrapper img {
  display: block;
  max-width: 80%;
}
.page2-product .product-block .block-image-wrapper.right {
  float: right;
  justify-content: flex-end;
}
.parallax-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
}
.parallax-bg.bottom {
  z-index: 0;
}
.parallax-bg.top {
  margin-top: 220px;
  display: flex;
  justify-content: center;
}
.parallax-bg.top svg {
  overflow: inherit;
}
/** page3 **/
.page3 {
  min-height: 556px;
}
.page3-block {
  display: flex;
  justify-content: center;
  padding: 24px 32px;
}
.page3-img-wrapper,
.page3-text-wrapper {
  display: inline-block;
}
.page3-img-wrapper {
  margin-top: 8px;
}
.page3-text-wrapper {
  max-width: 182px;
  margin-left: 32px;
  line-height: 24px;
}
.page3-text-wrapper h3 {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 4px;
}
.page3 .parallax-bg.top {
  margin: 0;
}
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
.steps-action {
  margin-top: 24px;
}
